import React from "react";
import Container from "../components/atoms/Container";
import Heading from "../components/atoms/Heading";

const NotFound = () => {
  return (
    <Container>
      <Heading tag="h3" classNames="text-red-500">
        404 not found
      </Heading>
    </Container>
  );
};

export default NotFound;
