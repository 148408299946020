import React from "react";
import { Menu } from "react-feather";
import { Link } from "react-router-dom";
import { useUser } from "../context/UserProvider";
import Container from "./atoms/Container";
import Badge from "./Badge";
import UserArea from "./UserArea";
import { useAuth0 } from "@auth0/auth0-react";

const colors = require("../colors");

const Navbar: React.FC = () => {
  const { user } = useUser();
  const { isAuthenticated } = useAuth0();
  return (
    <nav className="navigation">
      <Container classNames="flex items-center justify-between py-2">
        <div className="flex items-center">
          <Link to="/">
            <Menu size={36} />
          </Link>
          {isAuthenticated && user && user?.license && (
            <Badge bgColor={colors.grey[200]} classNames="ml-4">
              {user.license.name}
            </Badge>
          )}
        </div>
        <UserArea />
      </Container>
    </nav>
  );
};

export default Navbar;
