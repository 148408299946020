import classnames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import { IQuestion } from "../interfaces/question.interface";

interface IProps {
  question: IQuestion;
  isActive?: boolean;
  onClick?: () => void;
}

const QuestionMenuItem: React.FC<IProps> = ({
  question,
  isActive = false,
  onClick,
}) => {
  // const { user } = useUser();
  // TODO: replace with user info
  return (
    <Link
      className={classnames(`question-menu__item`, {
        "is-correct":
          question.isAnswered && question.answeredCorrectly && !isActive,
        "is-wrong":
          question.isAnswered && !question.answeredCorrectly && !isActive,
        "is-selected": isActive,
      })}
      to={`/questionnaire/ppl_a/${question.subject.abbreviation}?q=${question.id}`}
      onClick={onClick}
    >
      <span>{question.index}</span>
    </Link>
  );
};

export default QuestionMenuItem;
