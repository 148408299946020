import API_URL from "../const/apiUrl";
import { defaultHeaders } from "../const/request";

export const getUser = (token: any): Promise<Response> => {
  return fetch(`${API_URL}/users/me`, {
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
};

export async function updateUser({ token, body }: any) {
  const response = await fetch(`${API_URL}/users/me`, {
    method: "PUT",
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });

  const { data } = await response.json();

  return data;
}
