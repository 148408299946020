import API_URL from "../const/apiUrl";
import { defaultHeaders } from "../const/request";

export async function getLicenses({ queryKey }: any) {
  const { token } = queryKey[1];

  const response = await fetch(`${API_URL}/licenses`, {
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    },
  });

  const { data } = await response.json();

  return data;
}
