import { useQuery } from "react-query";
import { useUser } from "../context/UserProvider";
import {
  IQuestion,
  IQuestionBySubjectParams,
  IQuestionFilters,
} from "../interfaces/question.interface";
import { getQuestionsBySubject } from "../services/question.service";

interface IUseQuestions {
  questions: IQuestion[] | undefined;
  isLoading: boolean;
  isError: boolean;
}

const useQuestionsState = (filters?: IQuestionFilters): IUseQuestions => {
  const { user, token } = useUser();

  const {
    isLoading,
    isError,
    data: questions,
  } = useQuery<
    IQuestion[],
    any,
    IQuestion[],
    [string, IQuestionBySubjectParams]
  >(
    [
      "getQuestionsBySubject",
      {
        token,
        lang: user?.lang,
        ...filters,
      },
    ],
    getQuestionsBySubject,
    {
      enabled: !!user && !!token && !!user.lang,
    }
  );

  return { questions, isLoading, isError };
};

export default useQuestionsState;
