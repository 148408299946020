const parseIntQuery = (
  query: URLSearchParams,
  param: string
): number | null => {
  const q = query.get(param);

  if (q && !isNaN(parseInt(q as string))) {
    return parseInt(q);
  }

  return null;
};

export default parseIntQuery;
