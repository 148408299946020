import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { useCallback, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Container from "../components/atoms/Container";
import Heading from "../components/atoms/Heading";
import Loader from "../components/Loader";
import Question from "../components/Question";
import QuestionMenu from "../components/QuestionMenu";
import Toolbar from "../components/Toolbar";
import { useUser } from "../context/UserProvider";
import useQueryParams from "../hooks/useQueryParams";
import useQuestionsState from "../hooks/useQuestions";
import { IAnswer } from "../interfaces/answer.interface";
import { IQuestionnaireParams } from "../interfaces/params.interface";
import { IQuestion } from "../interfaces/question.interface";
import { answer } from "../services/question.service";
import parseIntQuery from "../utils/parseIntQuery";

const Questionnaire: React.FC = () => {
  const { license, subject } = useParams<IQuestionnaireParams>();
  const { token } = useUser();
  const queryClient = useQueryClient();
  const { questions, isLoading } = useQuestionsState({ subject });
  const query = useQueryParams();
  const history = useHistory();

  const [currentQuestion, setCurrentQuestion] = useState<IQuestion | null>(
    null
  );
  const [selectedAnswer, setSelectedAnswer] = useState<IAnswer | null>(null);
  const [isResult, setIsResult] = useState<boolean>(false);

  useEffect(() => {
    if (questions && questions.length > 0) {
      const id = parseIntQuery(query, "q");

      if (id) {
        const question = questions.find(
          (question: IQuestion) => question.id === id
        );

        if (question) {
          setCurrentQuestion(question);
          return;
        }
      }

      setCurrentQuestion(questions[0]);
      history.push(`/questionnaire/${license}/${subject}?q=${questions[0].id}`);
    }
  }, [questions, query, license, subject, history, setCurrentQuestion]);

  const onSelectAnswer = useCallback(
    (answer: IAnswer) => {
      if (selectedAnswer && selectedAnswer.id === answer.id) {
        setSelectedAnswer(null);
        return;
      }

      setSelectedAnswer(answer);
    },
    [selectedAnswer]
  );

  const sendAnswer = useMutation(answer, {
    onSuccess: async () => {
      queryClient.invalidateQueries("getQuestionsBySubject");
      queryClient.invalidateQueries("getStats");
    },
  });

  const onAnswer = useCallback(() => {
    if (isResult || !selectedAnswer || !currentQuestion) {
      return;
    }

    const data = {
      questionId: currentQuestion.id,
      correct: selectedAnswer.isCorrect,
    };

    if (!currentQuestion.isAnswered) {
      sendAnswer.mutate({
        method: "post",
        data,
        token,
      });
    }

    if (
      currentQuestion.isAnswered &&
      currentQuestion.answeredCorrectly !== selectedAnswer.isCorrect
    ) {
      sendAnswer.mutate({
        method: "put",
        data,
        token,
      });
    }

    setIsResult(true);
  }, [isResult, currentQuestion, selectedAnswer, sendAnswer, token]);

  const onNext = useCallback(() => {
    if (questions && currentQuestion) {
      const currentIndex = questions.indexOf(currentQuestion);

      if (currentIndex !== -1) {
        let nextIndex = currentIndex + 1;

        if (!questions[nextIndex]) {
          nextIndex = 0;
        }

        history.push(
          `/questionnaire/${license}/${subject}?q=${questions[nextIndex].id}`
        );
        setCurrentQuestion(questions[nextIndex]);
        setSelectedAnswer(null);
        setIsResult(false);
      }
    }
  }, [
    questions,
    currentQuestion,
    history,
    license,
    subject,
    setCurrentQuestion,
  ]);

  const onNavigate = useCallback(() => {
    setIsResult(false);
  }, []);

  if (isLoading) {
    return (
      <div className="text-center mt-2">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Container classNames="py-5 mb-14 questionnaire">
        {questions && questions.length === 0 && (
          <>
            <Heading tag="h4" classNames="mb-6">
              🤔 There are no questions availiable for this subject
            </Heading>
            <Link
              to="/"
              className="bg-darkBlue text-white rounded disabled:bg-grey-500 py-2 px-6 text-base"
            >
              Go back to subjects
            </Link>
          </>
        )}
        {currentQuestion && (
          <Question
            question={currentQuestion}
            selectedAnswer={selectedAnswer}
            isResult={isResult}
            onSelectAnswer={onSelectAnswer}
          />
        )}
        <div className="hidden lg:block">
          <QuestionMenu
            currentQuestion={currentQuestion}
            onClick={onNavigate}
          />
        </div>
      </Container>
      <Toolbar
        currentQuestion={currentQuestion}
        selectedAnswer={selectedAnswer}
        isResult={isResult}
        onNext={onNext}
        onAnswer={onAnswer}
        onNavigate={onNavigate}
      />
    </>
  );
};

export default withAuthenticationRequired(Questionnaire);
