import { withAuthenticationRequired } from "@auth0/auth0-react";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory } from "react-router";
import Button from "../components/atoms/Button";
import Container from "../components/atoms/Container";
import Heading from "../components/atoms/Heading";
import Loader from "../components/Loader";
import { useUser } from "../context/UserProvider";
import { ILicense } from "../interfaces/license.interface";
import { getLicenses } from "../services/licenses.service";
import { updateUser } from "../services/user.service";

const Licenses: React.FC = () => {
  const history = useHistory();
  const { token, user, refreshUser } = useUser();
  const { data: licenses, isLoading } = useQuery(
    ["getLicenses", { token }],
    getLicenses,
    { enabled: !!token }
  );

  const updateLicense = useMutation(updateUser, {
    onSuccess: () => {
      refreshUser(() => {
        history.push("/");
      });
    },
  });

  const [selectedLicense, setSelectedLicense] = useState<ILicense | null>(
    user?.license
  );

  useEffect(() => {
    if (user && user.license) {
      setSelectedLicense(user.license);
    }
  }, [user]);

  if (isLoading) {
    return (
      <Container classNames="mt-4 flex justify-center">
        <Loader />
      </Container>
    );
  }

  const onChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;

    if (value && value !== "None") {
      setSelectedLicense(
        licenses.find((license: ILicense) => license.code === value)
      );
    }
  };

  const onSave = () => {
    if (selectedLicense && selectedLicense.id !== user?.license?.id) {
      updateLicense.mutate({
        token,
        body: {
          licenseId: selectedLicense.id,
        },
      });
    }
  };

  return (
    <Container classNames="mt-4">
      <Heading tag="h3" classNames="mb-4">
        Choose a license
      </Heading>
      <select
        className="px-4 py-2 mb-4"
        name="choose_license"
        id="chooseLicense"
        value={selectedLicense?.code}
        onChange={(e) => onChange(e)}
      >
        <option value="None">Choose an option</option>
        {licenses?.map((license: ILicense) => (
          <option key={license.id} value={license.code}>
            {license.name}
          </option>
        ))}
      </select>
      <div>
        <Button onClick={onSave}>Save</Button>
      </div>
    </Container>
  );
};

export default withAuthenticationRequired(Licenses);
