import React from "react";
import IBaseProps from "../interfaces/baseProps.interface";
import Text from "./atoms/Text";
import getTextColor from "../utils/getTextColor";

const colors = require("../colors");

interface Props extends IBaseProps {
  bgColor?: string;
}

const Badge: React.FC<Props> = ({
  bgColor = colors.grey[400],
  children,
  classNames = "",
}) => {
  return (
    <div
      className={`inline-block leading-3 px-2 py-1 rounded-full text-black select-none ${classNames}`}
      style={{ backgroundColor: bgColor, color: getTextColor(bgColor) }}
    >
      <Text tag="small">{children}</Text>
    </div>
  );
};

export default React.memo(Badge);
