import React from "react";
import ReactDOM from "react-dom";
import { X } from "react-feather";
import IBaseProps from "../interfaces/baseProps.interface";
import Button from "./atoms/Button";

interface Props extends IBaseProps {
  onClose: () => void;
}

const Modal: React.FC<Props> = ({ children, onClose, classNames = "" }) => {
  const node = document.getElementById("root-modal") as Element;

  const passChildren = (
    <>
      <div className="modal is-open">
        <div className="modal__backdrop" onClick={onClose}></div>
        <div className="modal__close">
          <Button type="ghost" dimension="slim" onClick={onClose}>
            <X />
          </Button>
        </div>
        <div className="modal__content">{children}</div>
      </div>
    </>
  );

  return ReactDOM.createPortal(passChildren, node);
};

export default Modal;
