import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Navbar from "./components/Navbar";
import { QuestionsProvider } from "./context/QuestionsProvider";
import { UserProvider } from "./context/UserProvider";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Questionnaire from "./pages/Questionnaire";
import Licenses from "./pages/Licenses";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});

function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <QuestionsProvider>
            <Router>
              <Navbar />
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path="/licenses">
                  <Licenses />
                </Route>
                <Route exact path="/questionnaire/:license/:subject">
                  <Questionnaire />
                </Route>
                <Route path="/callback"></Route>
                <Route exact path="/404">
                  <NotFound />
                </Route>
                <Route path="*">
                  <NotFound />
                </Route>
              </Switch>
            </Router>
          </QuestionsProvider>
        </UserProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
