import React, { useState } from "react";
import { IQuestion } from "../interfaces/question.interface";
import Answer from "./Answer";
import Heading from "./atoms/Heading";
import Badge from "./Badge";
import { IAnswer } from "../interfaces/answer.interface";
import Lightbox from "react-awesome-lightbox";

const colors = require("../colors");

interface Props {
  question: IQuestion;
  selectedAnswer: IAnswer | null;
  isResult: boolean;
  onSelectAnswer: (answer: IAnswer) => void;
}

const Question: React.FC<Props> = ({
  question,
  selectedAnswer,
  isResult,
  onSelectAnswer,
}) => {
  const { index, title, subject, answers, asset } = question;
  const [zoomIsOpen, setZoomIsOpen] = useState(false);

  return (
    <div className="question">
      <div className="question__labels mb-2">
        <Badge bgColor={colors.darkBlue} classNames="mr-1">
          {subject.name}
        </Badge>
        {asset && <Badge>Attachment</Badge>}
      </div>
      <div className="question__title flex mb-5">
        <Heading tag="h5" classNames="leading-6 mr-1">
          {index}.
        </Heading>
        <Heading tag="h5" dangerouslySetInnerHTML={{ __html: title }} />
      </div>
      <div className="question__answers">
        {answers.map((answer) => (
          <Answer
            key={answer.id}
            classNames="mb-4"
            answer={answer}
            isSelected={selectedAnswer?.id === answer.id}
            isResult={isResult}
            onSelectAnswer={onSelectAnswer}
          />
        ))}
      </div>
      {asset && (
        <div className="question__asset mt-5">
          <img src={asset} alt="" onClick={() => setZoomIsOpen(true)} />
          {zoomIsOpen && (
            <Lightbox
              image={asset}
              allowRotate={false}
              onClose={() => setZoomIsOpen(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Question;
