import React from "react";
import classnames from "classnames";
import { IAnswer } from "../interfaces/answer.interface";

interface Props {
  answer: IAnswer;
  isSelected: boolean;
  isResult?: boolean;
  classNames?: string;
  onSelectAnswer?: (answer: IAnswer) => void;
}

function isTouchDevice() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    (navigator as any).msMaxTouchPoints > 0
  );
}

const Answer: React.FC<Props> = ({
  answer,
  isSelected = false,
  isResult = false,
  classNames = "",
  onSelectAnswer = () => {},
}) => {
  const onClick = () => {
    onSelectAnswer(answer);
  };

  return (
    <button
      aria-checked={isSelected}
      role="checkbox"
      onClick={onClick}
      disabled={isResult}
      className={classnames(`answer ${classNames}`, {
        "is-selected": isSelected && !isResult,
        "has-hover": !isResult && !isTouchDevice(),
        "is-correct": isResult && answer.isCorrect,
        "is-wrong": isSelected && isResult && !answer.isCorrect,
      })}
      dangerouslySetInnerHTML={{ __html: answer.title }}
    ></button>
  );
};

export default Answer;
