import React, { useCallback } from "react";
import Text from "./atoms/Text";

interface IProps {
  correct: number;
  wrong: number;
  total: number;
}

const MultiProgressBar: React.FC<IProps> = ({ correct, wrong, total }) => {
  const calculateWidth = useCallback((value: number, total: number): number => {
    return (value * 100) / total;
  }, []);

  const correctWidthPercentage = calculateWidth(correct, total);
  const wrongWidthPercentage = calculateWidth(wrong, total);

  return (
    <>
      <div className="relative flex w-full h-4 bg-grey-500 rounded-full mb-1">
        {correct > 0 && (
          <div
            className={`relative z-20 h-full min-w-[10px] rounded-full bg-green-500 mr-[-10px]`}
            style={{ width: `${correctWidthPercentage}%` }}
          ></div>
        )}
        {wrong > 0 && (
          <div
            className={`relative z-10 h-full rounded-full bg-red-500`}
            style={{ width: `calc(${wrongWidthPercentage}% + 10px)` }}
          ></div>
        )}
      </div>
      <div>
        <Text tag="span" classNames="text-green-500 font-bold">
          {correct} correct
        </Text>{" "}
        -{" "}
        <Text tag="span" classNames="text-red-500 font-bold">
          {wrong} wrong
        </Text>{" "}
        -{" "}
        <Text tag="span" classNames="font-bold">
          {total - (correct + wrong)} unanswered
        </Text>
      </div>
    </>
  );
};

export default MultiProgressBar;
