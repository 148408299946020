import React from "react";
import IBaseProps from "../../interfaces/baseProps.interface";
import classnames from "classnames";
interface Props extends IBaseProps, React.HTMLProps<HTMLButtonElement> {
  type?: "primary" | "secondary" | "ghost";
  dimension?: "small" | "medium" | "large" | "slim";
  onClick: () => void;
}

const dimensionClassMap: Record<string, string> = {
  small: "py-1 px-3 text-sm",
  medium: "py-2 px-6 text-base",
  slim: "py-2 px-2 text-base",
};

const typeClassMap: Record<string, string> = {
  primary: "bg-darkBlue text-white rounded disabled:bg-grey-500",
  secondary:
    "text-darkBlue border border-darkBlue rounded disabled:text-grey-500 disabled:border-grey-500",
  ghost: "text-white disabled:text-grey-500",
};

const Button: React.FC<Props> = ({
  type = "primary",
  dimension = "medium",
  classNames = "",
  children,
  onClick,
  ...rest
}): React.ReactElement<HTMLButtonElement> => {
  return (
    <button
      className={classnames(
        `rounded ${classNames} ${dimensionClassMap[dimension]} ${typeClassMap[type]}`
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
};

export default Button;
