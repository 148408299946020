import React from "react";
import { useParams } from "react-router";
import useQuestionsState from "../hooks/useQuestions";
import { IQuestionnaireParams } from "../interfaces/params.interface";
import { IQuestion } from "../interfaces/question.interface";
import Container from "./atoms/Container";
import QuestionMenuItem from "./QuestionMenuItem";

interface IProps {
  currentQuestion: IQuestion | null;
  onClick?: () => void;
}

const QuestionMenu: React.FC<IProps> = ({ currentQuestion, onClick }) => {
  const { subject } = useParams<IQuestionnaireParams>();
  const { questions } = useQuestionsState({ subject });

  return (
    <div className="question-menu lg:max-h-[500px]">
      <Container classNames="py-5">
        {questions?.map((question: IQuestion, index: number) => (
          <QuestionMenuItem
            key={question.id}
            question={question}
            isActive={question.id === currentQuestion?.id}
            onClick={onClick}
          />
        ))}
      </Container>
    </div>
  );
};

export default React.memo(QuestionMenu);
