import React from "react";
import { useQuery } from "react-query";
import Container from "../components/atoms/Container";
import SubjectCard from "../components/SubjectCard";
import API_URL from "../const/apiUrl";
import { useUser } from "../context/UserProvider";
import ISubject from "../interfaces/subject.interface";
import Loader from "../components/Loader";
import Heading from "../components/atoms/Heading";
import { useAuth0 } from "@auth0/auth0-react";
import Text from "../components/atoms/Text";
import MultiProgressBar from "../components/MultiProgressBar";
import { useHistory } from "react-router";

const HomeStats: React.FC = () => {
  const { user, token } = useUser();

  const { isLoading, data } = useQuery(
    ["getStats", { lang: user?.lang, token }],
    async ({ queryKey }: any) => {
      const { lang, token } = queryKey[1];
      const response = await fetch(
        `${API_URL}/questions/me/stats?lang=${lang}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = await response.json();
      return data;
    },
    {
      enabled: !!token && !!user && !!user.lang,
    }
  );

  return data ? (
    <MultiProgressBar
      correct={data.correct}
      wrong={data.wrong}
      total={data.total}
    />
  ) : null;
};

const Home: React.FC = () => {
  const history = useHistory();
  const { isAuthenticated } = useAuth0();
  const { user, token } = useUser();

  if (user && !user.license) {
    history.push("/licenses");
  }

  const { isLoading, data } = useQuery(
    ["getSubjects", { id: user?.license?.id, token }],
    async ({ queryKey }: any) => {
      const { id, token } = queryKey[1];
      const response = await fetch(`${API_URL}/licenses/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { data } = await response.json();
      return data;
    },
    {
      enabled: !!token && !!user && !!user.license,
    }
  );

  if (isLoading) {
    return (
      <div className="text-center">
        <Loader />
      </div>
    );
  }

  return (
    <main className="my-4">
      <Container>
        {!isAuthenticated && (
          <>
            <Heading classNames="mb-6">
              Welcome to the EASA Questionnaire App
            </Heading>
            <Text>Please log in to access the app</Text>
          </>
        )}
        {user && (
          <div className="mb-5">
            <div className="mb-4">
              <Heading tag="h2">Hello {user.name} 👨‍✈️</Heading>
            </div>
            <HomeStats />
          </div>
        )}
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {data?.subjects?.map(({ subject }: { subject: ISubject }) => (
            <SubjectCard
              key={subject.abbreviation}
              license={data.code}
              subject={{
                id: subject.id,
                name: subject.name,
                abbreviation: subject.abbreviation,
                icon: subject.icon,
              }}
            />
          ))}
        </div>
      </Container>
    </main>
  );
};

export default Home;
