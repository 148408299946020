import { useState } from "react";
import { useCallback } from "react";

const useToggle = (initialState: boolean = false): [boolean, () => void] => {
  // Initialize the state
  const [isOpen, setState] = useState<boolean>(initialState);

  // Define and memorize toggler function in case we pass down the comopnent,
  // This function change the boolean value to it's opposite value
  const toggle = useCallback(() => setState((isOpen) => !isOpen), []);

  return [isOpen, toggle];
};

export default useToggle;
