import React from "react";
import Button from "./atoms/Button";
import { useAuth0 } from "@auth0/auth0-react";
import { useUser } from "../context/UserProvider";

const UserArea: React.FC = () => {
  const { user } = useUser();
  const { loginWithPopup, logout, isAuthenticated } = useAuth0();

  const onLogIn = () => {
    loginWithPopup();
  };

  return (
    <div className="flex">
      {isAuthenticated ? (
        <Button
          type="secondary"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Log out
        </Button>
      ) : (
        <Button onClick={onLogIn}>Log in</Button>
      )}
      {isAuthenticated && user && (
        <div className="ml-1 w-9">
          <img
            className="rounded-full"
            src={`https://avatars.dicebear.com/api/identicon/${
              user.nickname || "pilot"
            }.svg`}
            alt="User avatar"
          />
        </div>
      )}
    </div>
  );
};

export default UserArea;
