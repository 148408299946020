import API_URL from "../const/apiUrl";
import { IAnswerQuestionDto } from "../interfaces/answer.interface";
import { IQuestion } from "../interfaces/question.interface";
import { defaultHeaders } from "../const/request";
import { buildQuery } from "../utils/buildQuery";

// TODO: handle errors
export const getQuestionsBySubject = async ({
  queryKey,
}: any): Promise<IQuestion[]> => {
  const { token, lang, ...filters } = queryKey[1];

  const response = await fetch(
    `${API_URL}/questions/me?lang=${lang}${
      Object.keys(filters).length ? buildQuery(filters, true) : ""
    }`,
    {
      headers: {
        ...defaultHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error("Not found");
  }

  const { data }: { data: IQuestion[] } = await response.json();

  return data.map((question, index) => ({
    ...question,
    index: index + 1,
  }));
};

export const answer = ({
  method,
  data,
  token,
}: IAnswerQuestionDto): Promise<Response> => {
  return fetch(`${API_URL}/answers`, {
    method,
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      questionId: data.questionId,
      correct: data.correct,
    }),
  });
};
