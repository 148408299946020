import React from "react";
import { useHistory } from "react-router";
import useToggle from "../hooks/useToggle";
import ISubject from "../interfaces/subject.interface";
import Button from "./atoms/Button";
import Container from "./atoms/Container";
import Heading from "./atoms/Heading";
import Modal from "./Modal";

interface IProps {
  subject: ISubject;
  license: string;
}

const SubjectCard: React.FC<IProps> = ({ subject, license }) => {
  const history = useHistory();
  const [isOpen, toggle] = useToggle(false);

  const onNavigate = () => {
    history.push(`/questionnaire/${license}/${subject.abbreviation}`);
  };

  return (
    <>
      <button
        className="relative p-3 bg-grey-200 flex justify-items-start items-end text-left w-100 min-h-140 rounded-md"
        onClick={toggle}
      >
        <div className="subject-card__icon">
          <img src={subject.icon} alt="Subject icon" />
        </div>
        <Heading tag="h6" classNames="relative z-10 leading-5">
          {subject.name}
        </Heading>
      </button>
      {isOpen && (
        <Modal onClose={toggle}>
          <div className="modal__body w-[90%]">
            <Heading tag="h6" classNames="mb-2">
              Choose an option
            </Heading>
            <Button onClick={onNavigate} classNames="mb-2">
              Go to questionnaire
            </Button>
            <Button type="secondary" onClick={() => {}}>
              Test
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default SubjectCard;
