import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { IQuestion } from "../interfaces/question.interface";

export const QuestionsProvider: React.FC = ({ children }) => {
  const [currentQuestion, setCurrentQuestion] = useState<IQuestion | null>(
    null
  );

  return (
    <QuestionsContext.Provider
      value={{
        currentQuestion,
        setCurrentQuestion,
      }}
    >
      {children}
    </QuestionsContext.Provider>
  );
};

export const QuestionsContext = createContext<{
  // questions?: IQuestion[];
  // isLoading?: boolean;
  currentQuestion?: IQuestion | null;
  // getQuestions?: (filters: any) => void;
  // setSubject?: Dispatch<SetStateAction<null>>;
  setCurrentQuestion?: Dispatch<SetStateAction<IQuestion | null>>;
  // onAnswer?: unknown;
}>({});

export const useQuestions = (): any => {
  const context = useContext(QuestionsContext);

  if (context === undefined) {
    throw new Error("useQuestions must be used within a QuestionsProvider");
  }
  return context;
};
