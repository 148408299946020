import React from "react";
import IBaseProps from "../../interfaces/baseProps.interface";

interface Props extends IBaseProps {}

const Container: React.FC<Props & React.HTMLProps<HTMLDivElement>> = ({
  classNames = "",
  children,
  ...rest
}): React.ReactElement<HTMLDivElement> => {
  return (
    <div className={`container mx-auto px-4 lg:px-0 ${classNames}`} {...rest}>
      {children}
    </div>
  );
};

export default Container;
