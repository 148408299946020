import React, { useRef } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import { useParams } from "react-router";
import useQuestionsState from "../hooks/useQuestions";
import useToggle from "../hooks/useToggle";
import { IAnswer } from "../interfaces/answer.interface";
import { IQuestionnaireParams } from "../interfaces/params.interface";
import { IQuestion } from "../interfaces/question.interface";
import Button from "./atoms/Button";
import Container from "./atoms/Container";
import QuestionMenu from "./QuestionMenu";

interface IProps {
  currentQuestion: IQuestion | null;
  selectedAnswer: IAnswer | null;
  isResult: boolean;
  onNext: () => void;
  onAnswer: () => void;
  onNavigate: () => void;
}

const Toolbar: React.FC<IProps> = ({
  currentQuestion,
  selectedAnswer,
  isResult,
  onNext,
  onAnswer,
  onNavigate,
}) => {
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [isOpen, toggle] = useToggle();
  const { subject } = useParams<IQuestionnaireParams>();
  const { questions } = useQuestionsState({ subject });

  const onQuestionMenuClick = (): void => {
    onNavigate();
    toggle();
  };

  return (
    <div className="toolbar" ref={toolbarRef}>
      <div className="toolbar__content">
        <Container classNames="flex py-2 lg:py-4 justify-between">
          <Button
            type="secondary"
            dimension="slim"
            classNames="flex items-center lg:hidden"
            onClick={toggle as any}
          >
            <span className="text-base mr-1">
              {currentQuestion?.index || 0} / {questions?.length || 0}
            </span>
            {!isOpen ? (
              <ChevronUp size={18} className="inline-block" />
            ) : (
              <ChevronDown size={18} className="inline-block" />
            )}
          </Button>
          {!isResult ? (
            <Button
              dimension="medium"
              onClick={onAnswer}
              disabled={!selectedAnswer || isResult}
            >
              Check
            </Button>
          ) : (
            <Button type="secondary" onClick={onNext} disabled={!isResult}>
              Next
            </Button>
          )}
        </Container>
      </div>
      <div
        className={`toolbar__menu ${isOpen ? "is-open" : ""}`}
        style={{ bottom: `${toolbarRef?.current?.clientHeight}px` }}
      >
        <QuestionMenu
          currentQuestion={currentQuestion}
          onClick={onQuestionMenuClick}
        />
      </div>
    </div>
  );
};

export default Toolbar;
