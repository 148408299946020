import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { getUser } from "../services/user.service";

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [token, setToken] = useState<string | null>(null);
  const {
    isAuthenticated,
    getAccessTokenSilently,
    user: userData,
  } = useAuth0();

  // console.log(token);

  useEffect(() => {
    const getUserData = async (token: string) => {
      const response = await getUser(token);
      const { data } = await response.json();

      setUser({ ...data, ...userData });
    };

    const getToken = async () => {
      const token = await getAccessTokenSilently();
      getUserData(token);

      setToken(token);
    };

    if (isAuthenticated) {
      getToken();

      return;
    }

    setToken(null);
    setUser(null);
  }, [isAuthenticated, userData, getAccessTokenSilently]);

  const refreshUser = (cb: Function) => {
    const getUserData = async (token: string) => {
      const response = await getUser(token);
      const { data } = await response.json();

      setUser({ ...data, ...userData });
      cb();
    };

    if (token) {
      getUserData(token);
    }
  };

  return (
    <UserContext.Provider value={{ user, token, refreshUser }}>
      {children}
    </UserContext.Provider>
  );
};

interface IUserContext {
  user?: any | null;
  token?: string | null;
  refreshUser?: (cb: () => void) => void;
}

export const UserContext = createContext<IUserContext>({});

export const useUser = (): any => {
  const context = useContext<IUserContext>(UserContext);

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
