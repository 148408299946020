module.exports = {
  transparent: "transparent",
  black: "#000",
  white: "#fff",
  darkBlue: "#03256C",
  grey: {
    100: "#F5F5F5",
    200: "#EBEBEB",
    300: "#E0E0E0",
    400: "#D6D6D6",
    500: "#CCCCCC",
    600: "#C2C2C2",
    700: "#B8B8B8",
    800: "#ADADAD",
    900: "#A3A3A3",
  },
  green: {
    100: "#AFFED8",
    200: "#87FDC4",
    300: "#4BFBA6",
    400: "#0FFA89",
    500: "#04DC74",
    600: "#04C869",
    700: "#04B45F",
    800: "#038C4A",
    900: "#026435",
  },
  blue: {
    100: "#C2E4FF",
    200: "#85CAFF",
    300: "#47AFFF",
    400: "#1F9EFF",
    500: "#008BF8",
    600: "#007FE0",
    700: "#0068B8",
    800: "#00518F",
    900: "#003A66",
  },
  red: {
    100: "#FFC2D2",
    200: "#FF5C87",
    300: "#FF1F5A",
    400: "#F50041",
    500: "#F50041",
    600: "#E0003C",
    700: "#B80031",
    800: "#8F0026",
    900: "#66001B",
  },
  yellow: {
    100: "#FFF4AE",
    200: "#FEEE85",
    300: "#FEE95D",
    400: "#FEE334",
    500: "#FEE020",
    600: "#FEDD0B",
    700: "#DFC201",
    800: "#B79E01",
    900: "#8E7B01",
  },
};
