import React from "react";
import IBaseProps from "../../interfaces/baseProps.interface";

interface Props extends IBaseProps, React.HTMLProps<HTMLHeadingElement> {
  tag?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const classMap: Record<string, any> = {
  h1: "font-bold text-5xl",
  h2: "font-bold text-4xl",
  h3: "font-bold text-3xl",
  h4: "font-bold text-2xl",
  h5: "font-bold text-xl",
  h6: "font-bold text-base",
};

const Heading: React.FC<Props> = ({
  tag = "h1",
  classNames = "",
  children,
  ...rest
}): React.ReactElement<HTMLHeadingElement> => {
  const Tag = tag;
  return (
    <Tag
      className={`font-heading dark:text-red-500 ${classMap[tag]} ${classNames}`}
      {...rest}
    >
      {children}
    </Tag>
  );
};

export default React.memo(Heading);
