import React from "react";
import IBaseProps from "../../interfaces/baseProps.interface";
interface Props extends IBaseProps {
  tag?: "p" | "span" | "small";
}

const classMap: Record<string, string> = {
  p: "text-base mb-2",
  small: "text-xs",
  span: "text-base",
};

const Text: React.FC<Props> = ({
  tag = "p",
  classNames = "",
  children,
  ...rest
}): React.ReactElement<
  HTMLParagraphElement | HTMLSpanElement | HTMLElement
> => {
  const Tag = tag;
  return (
    <Tag className={`font-sans ${classMap[tag]} ${classNames}`} {...rest}>
      {children}
    </Tag>
  );
};

export default React.memo(Text);
